<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="title">欢迎使用打扫客户端</div>
  <div class="ipt-view">
    <input
      class="phone"
      type="phone"
      placeholder="请输入手机号"
      maxlength="11"
      v-model="phone"
      @blur="checkPhone()"
    />
    <div class="errorDiv">{{ s_telephone1 }}</div>
    <div class="code">
      <input
        class="phone_code"
        type="text"
        placeholder="请输入验证码"
        v-model="code"
        @blur="checkPasswords()"
      />
      <button class="phone_btn" v-if="getCodeBl">{{ getCode }}</button>
      <button class="phone_btn" v-else @click="getPhoneCode">获取验证码</button>
    </div>
    <div class="errorDiv">{{ s_password1 }}</div>
    <button class="sub" @click="submit">立即登录</button>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { getCurrentInstance } from "vue";
import { showFailToast } from "vant";

export default {
  data() {
    return {
      name: "登录页",
      getCodeBl: false,
      getCode: 60,
      phone: "",
      code: "",
      s_telephone1: "",
      s_password1: "",
    };
  },
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const getCode = () => {
      let appid = "wx648a06245248904b"; //appid
      let redirect = encodeURIComponent(window.location.href); //重定向回来的地址  http://192.168.10.102:8081/#/
      let wx_code = getUrlParam("code"); // 截取url中的code
      //判断有没有code
      if (!wx_code) {
        //获取code的地址。获取成功重定向后地址栏中将会带有code，判断没有code的话，就跳转到微信官方链接上获取，获取成功后会再重定向回来，注意url是需要使用encodeURIComponent处理一下编码的
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
      } else {
        // 获取到了code
        getOpenId(wx_code); //把code传给后台获取用户信息
      }
    };
    //getUrlParam方法就是使用正则截取地址栏里的code
    const getUrlParam = (name) => {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    };
    const getOpenId = (code) => {
      //这里调用后端给的接口吧code传过去就好了
      console.log(code);
      proxy
        .$http({
          url: "/clean/login/getBaseInfo?agent_id=40&code=" + code,
        })
        .then((res) => {
          if (res.data.code == 0) {
            let { data } = res.data;
            console.log(data, res);
            let isBind = data.is_bind;
            let openId = data.open_id;
            let token = data.token;
            let phone = data.mobile;
            localStorage.setItem("openid", openId);
            sessionStorage.setItem("token", token);
            if (openId == "") {
              showFailToast("id未获取到");
            }
            if (isBind) {
              router.push({
                path: "/index/be",
                query: {
                  phone: phone,
                },
              });
            }
          } else {
            showFailToast(res.data.msg);
          }
          // let token = data.token
          // console.log(isBind, openId)
        })
        .catch(() => {
          showFailToast("网络错误");
        });
    };
    getCode();
    // getOpenId()

    function getData(phone) {
      proxy
        .$http({
          url: "/clean/login/smsCode?agent_id=40&mobile=" + phone,
        })
        .then((res) => {
          let { data } = res.data;
          console.log(data, res);
        });
    }
    function login(openid) {
      proxy
        .$http({
          url: "/clean/login/login",
          params: {
            open_id: openid,
            mobile: this.phone,
            vertify_code: this.code,
            agent_id: 40,
          },
        })
        .then((res) => {
          let data = res.data;
          console.log(data, res);
          if (data.code == 0) {
            router.push({
              path: "/index",
              query: {
                phone: this.phone,
              },
            });
            localStorage.removeItem("openid");
            sessionStorage.setItem("token", data.data.token);
          } else {
            this.s_password1 = data.msg;
          }
        });
    }
    return {
      router,
      getData,
      login,
      getUrlParam,
      getOpenId,
    };
  },
  methods: {
    //手机号1校验
    checkPhone() {
      //定义phone，通过唯一id拿到input框输入的值
      const phone = this.phone;

      //手机号正则校验
      if (phone === "") {
        //若手机号为空
        this.s_telephone1 = "请输入手机号码";
        return false;
      }else {
        //手机号正确则无错误信息
        this.s_telephone1 = "";
        return true;
      }

    },
    // 密码校验
    checkPasswords() {
      //定义password，通过唯一id拿到input框输入的值
      const password = this.code;
      if (password === "") {
        //密码正则校验
        this.s_password1 = "请输入验证码";
        return false;
      } else {
        //密码正确则无错误信息
        this.s_password1 = "";
        return true;
      }
    },
    // 获取手机验证码
    getPhoneCode() {
      const phoneCode = this.checkPhone();
      if (phoneCode) {
        this.getCode = 60;
        this.getCodeBl = true;
        let phone = this.phone;
        console.log(phone);
        this.getData(phone); //调用请求验证码函数
        var setCode = setInterval(() => {
          this.getCode--;
          if (this.getCode < 1) {
            clearTimeout(setCode);
            this.getCodeBl = false;
          }
        }, 1000);
      }
    },
    // 登录
    submit() {
      const phone = this.checkPhone();
      const code = this.checkPasswords();
      const openid = localStorage.getItem("openid");
      if (phone && code) {
        this.login(openid);
      }
    },
  },
};
</script>

<style scoped lang="less">
.title {
  margin-top: 50px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 100px;
}
.ipt-view {
  padding: 0 10px;
  .errorDiv {
    width: 100%-5px;
    height: 30px;
    line-height: 30px;
    color: #ff0000;
    text-align: left;
    font-size: 14px;
    padding-left: 20px;
  }
  .phone {
    width: 100%-5px;
    height: 50px;
    border-radius: 50px;
    font-size: 16px;
    background: #f1f5f8;
    border: none;
    padding-left: 20px;
    outline: none;

    // margin-bottom: 15px;
  }
  .code {
    display: flex;
    // margin-bottom: 20px;
    .phone_code {
      width: 65% - 5px;
      height: 50px;
      border-radius: 50px 0 0 50px;
      font-size: 16px;
      background: #f1f5f8;
      border: none;
      padding-left: 20px;
      outline: none;
    }
    .phone_btn {
      flex: 1;
      height: 50px;
      padding: 0;
      line-height: 50px;
      text-align: center;
      background: #f1f5f8;
      font-size: 16px;
      color: #000;
      border: none;
      font-weight: bold;
      border-radius: 0 50px 50px 0;
    }
  }
  .sub {
    width: 100%;
    height: 50px;
    padding: 0;
    line-height: 50px;
    text-align: center;
    border: none;
    font-size: 16px;
    font-weight: bold;
    border-radius: 50px;
    color: #ffffff;
    background: linear-gradient(243deg, #1271f6, #0ca3fa);
  }
}
</style>
